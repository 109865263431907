import * as Types from '../actions/devices';
import * as AppTypes from '../actions/app';
import {
  call,
  put,
  select,
  takeEvery,
  takeLatest,
  delay,
} from 'redux-saga/effects';
import Api from './Api';
import { translate } from 'react-i18nify';
import { getDeviceSettings } from '../selectors/devices';

export const devices = (
  state = {
    init: false,
    loading: false,
    agent_info: [],
    agent_threats: {},
    query: '',
    csv: {
      csvLoading: false,
      range: '',
    },
    show: false,
    statusLoading: false,
    connected: true,
    supportToolDevice: '',
    supportToolKey: '',
    lastStatus: translate('shared.unknown'),
    filename: translate('shared.unknown'),
    lastSync: 0,
    unknownStatus: false,
    deviceStatus: '',
    checkStatus: false,
  },
  action
) => {
  switch (action.type) {
    case Types.GET_DEVICES_DATA_SUCCESS:
      return {
        ...state,
        init: true,
        loading: false,
        agent_info: action.agent_info,
        agent_threats: action.agent_threats,
      };
    case Types.GET_DEVICES_DATA_FAILURE:
      return {
        ...state,
        init: true,
        loading: false,
      };
    case Types.ENDPOINT_LOGS_DOWNLOAD_CSV:
      return {
        ...state,
        csv: {
          ...state.csv,
          range: action.range,
          csvLoading: true,
        },
      };

    case Types.QUERY_DEVICE_LIST:
      return {
        ...state,
        query: action.query,
      };
    case Types.ENDPOINT_LOGS_DOWNLOAD_CSV_SUCCESS:
    case Types.ENDPOINT_LOGS_DOWNLOAD_CSV_FAILURE:
      return {
        ...state,
        csv: {
          ...state.csv,
          range: '',
          csvLoading: false,
        },
      };

    case Types.DEVICES_LOADING:
      return {
        ...state,
        loading: true,
      };
    case Types.ENDPOINT_LOGS_OPEN_SUPPORT_TOOL_MODAL:
      return {
        ...state,
        show: true,
        statusLoading: true,
        checkStatus: true,
        supportToolKey: action.device.key,
        supportToolDevice: action.device.name,
      };
    case Types.ENDPOINT_LOGS_CLOSE_SUPPORT_TOOL_MODAL:
      return {
        ...state,
        show: false,
        supportToolDevice: '',
        supportToolKey: '',
        filename: '',
        lastStatus: '',
        deviceStatus: '',
        checkStatus: false,
        unknownStatus: false,
        lastSync: 0,
      };
    case Types.ENDPOINT_LOGS_SEND_SUPPORT_COMMAND:
      return {
        ...state,
        generate: true,
        statusLoading: true,
        checkStatus: true,
      };
    case Types.ENDPOINT_LOGS_SEND_SUPPORT_COMMAND_SUCCESS:
      return {
        ...state,
        generate:
          action.device.device_status === 'Generating Logs' ? true : false,
        connected: action.device.connected,
        statusLoading: false,
        filename: action.device.file_name ? action.device.file_name : '-',
        unknownStatus: action.device.unknown,
        lastStatus: action.device.device_status
          ? action.device.device_status
          : '-',
        lastSync: action.device.last_sync,
        checkStatus: false,
        deviceStatus: action.device.online
          ? translate('components.supportTool.statusOnline')
          : action.device.connected
          ? translate('components.supportTool.statusOffline')
          : translate('components.supportTool.noConnection'),
      };
    case Types.ENDPOINT_LOGS_SEND_SUPPORT_COMMAND_FAILURE:
      return {
        ...state,
        generate: false,
        connected: false,
        checkStatus: false,
        filename: translate('components.supportTool.failFile'),
        lastStatus: translate('components.supportTool.failCommand'),
        deviceStatus: translate('shared.unknown'),
      };
    case Types.ENDPOINT_LOGS_GET_IOT_STATUS:
      return {
        ...state,
        connected: action.device.connected,
        generate:
          action.device.device_status === 'Generating Logs' ? true : false,
        statusLoading: true,
        filename: action.device.file_name ? action.device.file_name : '-',
        lastStatus: action.device.device_status
          ? action.device.device_status
          : '-',
        lastSync: action.device.last_sync,
        unknownStatus: action.device.unknown,
        deviceStatus: action.device.online
          ? translate('components.supportTool.statusOnline')
          : action.device.connected
          ? translate('components.supportTool.statusOffline')
          : translate('components.supportTool.noConnection'),
      };
    case Types.ENDPOINT_LOGS_GET_IOT_STATUS_SUCCESS:
      return {
        ...state,
        connected: action.device.connected,
        generate:
          action.device.device_status === 'Generating Logs' ? true : false,
        statusLoading: false,
        filename: action.device.file_name ? action.device.file_name : '-',
        lastStatus: action.device.device_status
          ? action.device.device_status
          : '-',
        checkStatus: false,
        lastSync: action.device.last_sync,
        unknownStatus: action.device.unknown,
        deviceStatus: action.device.online
          ? translate('components.supportTool.statusOnline')
          : action.device.connected
          ? translate('components.supportTool.statusOffline')
          : translate('components.supportTool.noConnection'),
      };
    case Types.ENDPOINT_LOGS_GET_IOT_STATUS_FAILURE:
      return {
        ...state,
        statusLoading: false,
        generate: false,
        checkStatus: false,
        filename: translate('shared.unknown'),
        lastStatus: translate('shared.unknown'),
        deviceStatus: translate('shared.unknown'),
      };
    default:
      return state;
  }
};

function* getDeviceList() {
  try {
    const store = yield select();
    yield put(Types.deviceLoading());
    const result = yield call(Api.getDeviceList, {
      page: 'devices',
      range: store.atp_shared.range,
      account_id: store.account.selected,
      time_zone: store.account.time_zone,
    });
    yield put(Types.getDeviceListSuccess(result));
  } catch (e) {
    yield put(AppTypes.error(e.message));
    yield put(Types.getDeviceListFailure(e.error));
  }
}

function* queryDeviceList() {
  try {
    yield delay(1000);

    const store = yield select();
    yield put(Types.deviceLoading());
    const result = yield call(Api.getDeviceList, {
      page: 'devices',
      range: store.atp_shared.range,
      account_id: store.account.selected,
      time_zone: store.account.time_zone,
      query: store.devices.query,
    });
    yield put(Types.getDeviceListSuccess(result));
  } catch (e) {
    yield put(AppTypes.error(e.message));
    yield put(Types.getDeviceListFailure(e.error));
  }
}

function* downloadCsv() {
  try {
    const store = yield select();
    const params = {
      job_type: 'endpoint_logs_csv',
      range: store.devices.csv.range,
      timezone: store.account.timezone,
    };

    const accountID = store.account.selected;
    const result = yield call(Api.csv.generate, accountID, params);
    const jobID = result.job_id;

    let i = 0;
    while (i++ < 30) {
      const result = yield call(Api.csv.checkJob, accountID, jobID);

      if (result.status === 'PENDING') {
        yield delay(1000 * (i * 2));
      } else {
        yield call(Api.csv.download, result.presignedURL, 'endpoint.csv');
        break;
      }
    }

    yield put(Types.downloadCsvSuccess());
  } catch (e) {
    yield put(AppTypes.error(translate('errors.downloadFailed')));
    yield put(Types.downloadCsvFailure(e));
  }
}

function* openSupportToolModal(device) {
  try {
    const store = yield select();
    const params = {
      method: 'get_iot_status',
      account_id: store.account.selected,
      deviceid: device.device.key,
      devicename: device.device.name,
    };
    let result = yield call(Api.iotCommand, params);
    let deviceSettings = yield select(getDeviceSettings);
    yield put(Types.getIoTStatus(deviceSettings));

    while (
      result.device_status === 'Generating Logs' &&
      deviceSettings.checkStatus
    ) {
      yield delay(30000);
      result = yield call(Api.iotCommand, params);
      deviceSettings = yield select(getDeviceSettings);
      if (result.device_status === 'Generating Logs') {
        yield put(Types.getIoTStatus(result));
      }
    }

    if (deviceSettings.show) {
      yield put(Types.getIoTStatusSuccess(result));
    }
  } catch (e) {
    yield put(Types.getIoTStatusFailure(e));
  }
}

function* sendSupportCommand() {
  try {
    const store = yield select();
    const params = {
      method: 'send_iot_support_command',
      account_id: store.account.selected,
      deviceid: store.devices.supportToolKey,
      devicename: store.devices.supportToolDevice,
    };

    yield call(Api.iotCommand, params);

    yield delay(3000);
    params.method = 'get_iot_status';
    let result = yield call(Api.iotCommand, params);
    let deviceSettings = yield select(getDeviceSettings);

    while (
      result.device_status === 'Generating Logs' &&
      deviceSettings.checkStatus
    ) {
      yield delay(30000);
      result = yield call(Api.iotCommand, params);
      deviceSettings = yield select(getDeviceSettings);
      if (result.device_status === 'Generating Logs') {
        yield put(Types.getIoTStatus(result));
      }
    }

    if (deviceSettings.show) {
      yield put(Types.sendSupportCommandSuccess(result));
    }
  } catch (e) {
    yield put(Types.sendSupportCommandFailure(e));
  }
}

export function* devicesReducerFlow() {
  yield takeEvery(Types.INIT_DEVICES_LIST, getDeviceList);
  yield takeLatest(Types.QUERY_DEVICE_LIST, queryDeviceList);
  yield takeLatest(Types.DEVICES_CHANGE_TIME, getDeviceList);
  yield takeEvery(Types.ENDPOINT_LOGS_DOWNLOAD_CSV, downloadCsv);
  yield takeEvery(Types.ENDPOINT_LOGS_SEND_SUPPORT_COMMAND, sendSupportCommand);
  yield takeEvery(
    Types.ENDPOINT_LOGS_OPEN_SUPPORT_TOOL_MODAL,
    openSupportToolModal
  );
}
