export const INIT_DEVICES_LIST = 'INIT_DEVICES_LIST';
export const DEVICES_LOADING = 'DEVICES_LOADING';
export const GET_DEVICES_DATA_SUCCESS = 'GET_DEVICES_DATA_SUCCESS';
export const GET_DEVICES_DATA_FAILURE = 'GET_DEVICES_DATA_FAILURE';
export const DEVICES_CHANGE_TIME = 'DEVICES_CHANGE_TIME';
export const ENDPOINT_LOGS_DOWNLOAD_CSV = ' ENDPOINT_LOGS_DOWNLOAD_CSV';
export const ENDPOINT_LOGS_DOWNLOAD_CSV_SUCCESS =
  'ENDPOINT_LOGS_DOWNLOAD_CSV_SUCCESS';
export const ENDPOINT_LOGS_DOWNLOAD_CSV_FAILURE =
  'ENDPOINT_LOGS_DOWNLOAD_CSV_FAILURE';
export const ENDPOINT_LOGS_SEND_SUPPORT_COMMAND =
  'ENDPOINT_LOGS_SEND_SUPPORT_COMMAND';
export const ENDPOINT_LOGS_SEND_SUPPORT_COMMAND_SUCCESS =
  'ENDPOINT_LOGS_SEND_SUPPORT_COMMAND_SUCCESS';
export const ENDPOINT_LOGS_SEND_SUPPORT_COMMAND_FAILURE =
  'ENDPOINT_LOGS_SEND_SUPPORT_COMMAND_FAILURE';
export const ENDPOINT_LOGS_OPEN_SUPPORT_TOOL_MODAL =
  'ENDPOINT_LOGS_OPEN_SUPPORT_TOOL_MODAL';
export const ENDPOINT_LOGS_CLOSE_SUPPORT_TOOL_MODAL =
  'ENDPOINT_LOGS_CLOSE_SUPPORT_TOOL_MODAL';
export const ENDPOINT_LOGS_GET_IOT_STATUS = 'ENDPOINT_LOGS_GET_IOT_STATUS';
export const ENDPOINT_LOGS_GET_IOT_STATUS_SUCCESS =
  'ENDPOINT_LOGS_GET_IOT_STATUS_SUCCESS';
export const ENDPOINT_LOGS_GET_IOT_STATUS_FAILURE =
  'ENDPOINT_LOGS_GET_IOT_STATUS_FAILURE';
export const QUERY_DEVICE_LIST = 'QUERY_DEVICE_LIST';

export const openSupportToolModal = device => ({
  type: 'ENDPOINT_LOGS_OPEN_SUPPORT_TOOL_MODAL',
  device,
});

export const closeSupportToolModal = () => ({
  type: 'ENDPOINT_LOGS_CLOSE_SUPPORT_TOOL_MODAL',
});

export const sendSupportCommand = device => ({
  type: 'ENDPOINT_LOGS_SEND_SUPPORT_COMMAND',
  device,
});

export const sendSupportCommandSuccess = device => ({
  type: 'ENDPOINT_LOGS_SEND_SUPPORT_COMMAND_SUCCESS',
  device,
});

export const sendSupportCommandFailure = error => ({
  type: 'ENDPOINT_LOGS_SEND_SUPPORT_COMMAND_FAILURE',
  error,
});

export const getIoTStatus = device => ({
  type: 'ENDPOINT_LOGS_GET_IOT_STATUS',
  device,
});

export const getIoTStatusSuccess = device => ({
  type: 'ENDPOINT_LOGS_GET_IOT_STATUS_SUCCESS',
  device,
});

export const getIoTStatusFailure = error => ({
  type: 'ENDPOINT_LOGS_GET_IOT_STATUS_FAILURE',
  error,
});

export const initDevicesList = () => ({
  type: INIT_DEVICES_LIST,
});

export const queryDeviceList = query => ({
  type: QUERY_DEVICE_LIST,
  query,
});

export const deviceLoading = () => ({
  type: DEVICES_LOADING,
});

export const getDeviceListSuccess = result => {
  let last_threat = [];
  let agent_threats = {};

  last_threat = result.agent_threats;

  const agent_info = result.agent_info.map(agent => ({
    key: agent.device_id,
    name: agent.device_name,
    version: agent.agent_version,
    os_version: agent.os_version,
  }));
  for (let threat of last_threat) {
    agent_threats[threat.hardware_uuid] = {
      [threat.beat.hostname]: {
        count: threat.total,
        last_threat: threat.timestamp,
      },
    };
  }

  return {
    type: GET_DEVICES_DATA_SUCCESS,
    agent_info,
    agent_threats,
  };
};

export const getDeviceListFailure = result => ({
  type: GET_DEVICES_DATA_FAILURE,
  result,
});

export const changeTimeframe = (time, index) => ({
  type: DEVICES_CHANGE_TIME,
  time,
  index,
});

export const downloadCsv = range => ({
  type: ENDPOINT_LOGS_DOWNLOAD_CSV,
  range,
});

export const downloadCsvSuccess = () => ({
  type: ENDPOINT_LOGS_DOWNLOAD_CSV_SUCCESS,
});

export const downloadCsvFailure = error => ({
  type: ENDPOINT_LOGS_DOWNLOAD_CSV_FAILURE,
  error,
});
